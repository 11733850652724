import { removeTrailingSlash } from '@/lib/string'

const isSSR = typeof window === 'undefined'

export const config = {
  isSSR,
  siteUrl: process.env.NEXT_PUBLIC_SITE_URL ?? '',
  backendUrl: process.env.BACKEND_URL ?? '',
  isDev: process.env.NEXT_PUBLIC_DEPLOY_TYPE === 'develop',
  isProd: process.env.NEXT_PUBLIC_DEPLOY_TYPE === 'production',
  yandexVerification: removeTrailingSlash(process.env.NEXT_PUBLIC_YANDEX_VERIFICATION ?? ''),
  yandexKey: removeTrailingSlash(process.env.NEXT_PUBLIC_YANDEX_KEY ?? ''),
  googleVerification: removeTrailingSlash(process.env.NEXT_PUBLIC_GOOGLE_VERIFICATION ?? ''),
  googleCaptchaKey: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY ?? '',
  yourgoodKey: removeTrailingSlash(process.env.NEXT_PUBLIC_YOURGOOD_KEY ?? ''),
  sentryDSN: removeTrailingSlash(process.env.NEXT_PUBLIC_SENTRY_DSN ?? ''),
}
